<template>
  <b-container>
    <b-row>
      <b-col cols="6" class="text-center">
        <donut-chart
          ref="donut1"
          id="donut1"
          :options="chartOptions1"
        ></donut-chart>
      </b-col>
      <b-col cols="6" class="text-center">
        <donut-chart
          ref="donut2"
          id="donut2"
          :options="chartOptions2"
        ></donut-chart>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { Chart } from "highcharts-vue";
export default {
  name: "MultipleDonutChart",
  components: {
    donutChart: Chart,
  },
  props: {
    title: {
      type: Array,
      default: () => [],
    },
    series1: {
      type: Array,
      default: () => [],
    },
    series2: {
      type: Array,
      default: () => [],
    },
    chart1Colors: {
      type: Array,
      default: () => [
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
    },
    chart2Colors: {
      type: Array,
      default: () => [
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
    },
  },

  data() {
    return {
      chartOptions1: {
        colors: [
          "#d81b60",
          "#64ffda",
          "#fb8c00",
          "#00acc1",
          "#d81b60",
          "#5e35b1",
          "#1e88e5",
          "#00897b",
        ],
        title: {
          text: "",
          style: { "font-weight": "bold", fontSize: "18px" },
          y: 28,
        },
        tooltip: {
          verticalAlign: "middle",
          floating: true,
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "75%",
            size: 240,
            zMin: 0,
            name: "",
            showInLegend: true,
            data: [],
            dataLabels: {
              enabled: false,
            },
          },
        ],
        chart: {
          type: "pie",
          width: 400,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                legendItemClick: null,
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: "left",
          itemStyle: {
            color: "#8394b4",
          },
        },
      },

      chartOptions2: {
        colors: [
          "#d81b60",
          "#64ffda",
          "#fb8c00",
          "#00acc1",
          "#d81b60",
          "#5e35b1",
          "#1e88e5",
          "#00897b",
        ],
        chart: {
          type: "pie",
          width: 400,
        },
        title: {
          text: "",
          style: { "font-weight": "bold", fontSize: "18px" },
          y: 28,
        },
        tooltip: {
          verticalAlign: "middle",
          floating: true,
        },
        series: [
          {
            minPointSize: 10,
            innerSize: "75%",
            size: 240,
            zMin: 0,
            name: "",
            showInLegend: true,
            data: [],
            dataLabels: {
              enabled: false,
            },
          },
        ],
        yAxis: {
          labels: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: "left",
          itemStyle: {
            color: "#8394b4",
          },
        },
      },
    };
  },
  beforeUpdate(){
    this.chartOptions1.title.text = this.title[0];
    this.chartOptions1.series[0].data = this.series1;
    this.chartOptions2.title.text = this.title[1];
    this.chartOptions2.series[0].data = this.series2;
    this.chartOptions1.colors = this.chart1Colors;
    this.chartOptions2.colors = this.chart2Colors;
    this.chartOptions1.plotOptions.series.point.events.legendItemClick = (function(
      component
    ) {
      return function() {
        const chart = component.$refs.donut2.chart;
        const series = chart.get(this.options.id);
        if (series) {
          if (this.visible) {
            series.setVisible(false);
          } else {
            series.setVisible(true);
          }
        }
      };
    })(this);
  },
  mounted() {
    this.chartOptions1.title.text = this.title[0];
    this.chartOptions1.series[0].data = this.series1;
    this.chartOptions2.title.text = this.title[1];
    this.chartOptions2.series[0].data = this.series2;
    this.chartOptions1.colors = this.chart1Colors;
    this.chartOptions2.colors = this.chart2Colors;
    this.chartOptions1.plotOptions.series.point.events.legendItemClick = (function(
      component
    ) {
      return function() {
        const chart = component.$refs.donut2.chart;
        const series = chart.get(this.options.id);
        if (series) {
          if (this.visible) {
            series.setVisible(false);
          } else {
            series.setVisible(true);
          }
        }
      };
    })(this);
  },
};
</script>

<style scoped>
::v-deep .highcharts-container {
  margin: 0 auto;
}

::v-deep .highcharts-axis.highcharts-yaxis {
  display: none;
}

::v-deep .highcharts-axis.highcharts-xaxis {
  display: none;
}

.donut-2 ::v-deep .highcharts-legend.highcharts-no-tooltip {
  visibility: hidden;
}
</style>
